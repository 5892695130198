.pointer {
  cursor: pointer;
}

.products-total {
    color: #7b170f;
    margin-bottom: 12.8px;
    margin-top: 12.8px;
    text-align: center;
  }

.btn-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .cart-btn {
    margin-bottom: 12.8px;
    width: 90%;
    height: 51.2px;
    font-size: 19.2px;
    color: #fff;
    border-radius: 8px;
    border: 0px;
  }
  
  .yellow {
    background-color: #f5c41d;
  }
  
  .brown {
    background-color: #7b170f;
  }