.slick-slide > * > * {
  display: flex !important;
}

.promo-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 6px;
    background-color: rgb(123, 23, 15);
    padding: 2px 10px;
    border-radius: 50px;
    width: 100%;
    text-align: center;
  }
  
  .promo-text {
    font-size: 14px;
    display: flex;
    align-items: center;
    color: white;
    margin: auto;
    width: 100%;
  }
  
  .promo-text p {
    display: flex;
    align-items: center;
    margin: 0;
    width: 100%;
  }
  
  .promo-text p.code-label {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }
  
  .promo-code {
    color: rgb(123, 23, 15);
    margin: auto;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    background-color: orange;
    padding: 2px;
    border-radius: 10px;
    width: 60%;
    text-align: center;
    box-shadow: 0px 2px 5px rgba(255, 255, 255, 0.55);
  }
  
  .promo-image {
    width: 40px;
    height: 40px;
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../../img/tag.svg");
    background-size: cover;
    background-position: center;
  }
  
  .promo-image p {
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    color: rgb(123, 23, 15);
    text-align: center;
    margin: 0;
  }
  
  
  /*.move {
      position: relative;
      animation: move-animation 2s infinite;
    }
    
    @keyframes move-animation {
      0% {
        left: 0;
      }
      50% {
        left: 10px;
      }
      100% {
        left: 0;
      }
    }*/

    
.move {
  position: relative;
  animation: move-animation 8s infinite;
}

@keyframes move-animation {
  0% {
    transform: translateX(0) scale(1);
    opacity: 1;
    left: 0;
  }
  20% {
    transform: translateX(100px) scale(0.6);
    opacity: 0.2;
    left: 0;
    /*transition: all 0.1s ease-in-out;*/
  }
  50% {
    transform: translateX(200px) scale(0.3);
    opacity: 0;
  }
  60% {
    transform: translateX(80px) scale(0.8);
    opacity: 0.5;
    transition: all 0.3s ease-in;
  }
 /* 80% {
    transform: translateX(100px) scale(0.6);
    opacity: 1;
  }*/
  75% {
    transform: translateX(0) scale(1);
    opacity: 1; /* L'élément est visible */
    left: 0;
  }
  80% {
    transform: translateX(0) scale(1.1);
    opacity: 1; /* L'élément est visible */
     left: 0;
  }
  90% {
    transform: translateX(0) scale(1.2);
    opacity: 1; /* Reste visible */
   
    left: 10px; 
  }

  100% {
    transform: translateX(0) scale(1.3);
    opacity: 1;
    left: 0;
    z-index: 50;
  }
}



.promo-s {
  display: none;
}

@media (max-width: 768px) {
  .promo-l {
    display: none;
  }
  .promo-s {
    display: block;
  }
  .head-style {
    margin-bottom: 60px !important;
  }
  
  @keyframes move-animation {
    0% {
      transform: translateX(0) scale(1);
      opacity: 1;
      left: 0;
    }
    20% {
      transform: translateX(100px) scale(0.6);
      opacity: 0.2;
      left: 0;
      /*transition: all 0.1s ease-in-out;*/
    }
    50% {
      transform: translateX(200px) scale(0.3);
      opacity: 0;
    }
    60% {
      transform: translateX(80px) scale(0.8);
      opacity: 0.5;
      transition: all 0.3s ease-in;
    }
   /* 80% {
      transform: translateX(100px) scale(0.6);
      opacity: 1;
    }*/
    75% {
      transform: translateX(0) scale(1);
      opacity: 1; /* L'élément est visible */
      left: 0;
    }
    80% {
      transform: translateX(0) scale(1);
      opacity: 1; /* L'élément est visible */
       left: 0;
    }
    90% {
      transform: translateX(0) scale(1);
      opacity: 1; /* Reste visible */
     
      left: 10px; 
    }

    100% {
      transform: translateX(0) scale(1);
      opacity: 1;
      left: 0;
    }
  }
}
