.cart-product {
  width: 100%;
}

.product-row {
    margin-top: 12.8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .product-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .product-name {
    font-weight: 600;
  }
  
  .product-number {
    font-size: 12.8px;
    color: #0369b2;
    margin-bottom: 8px;
  }
  
  .product-img {
    width: 115.2px;
    height: 76.8px;
    object-fit: cover;
    border-radius: 0px 32px 0px 32px;
  }
  
  .icon {
    width: 25px;
    height: 25px;
    margin-top: 12.8px;
    margin-bottom: 25.6px;
    padding: 6px;
    background-color: #7b170f;
    border-radius: 18px;
    cursor: pointer;
  }
  
  .counter {
    display: flex;
    align-items: center;
  }
  
  .counter-btn {
    border-radius: 4px;
    color: #fff;
    width: 25.6px;
    height: 25.6px;
    font-size: 16px;
    border: 0px;
  }
  
  .counter-btn_- {
    background-color: #e00b19;
    border-color: #e00b19;
    margin-right: 4px;
  }

  .quantityOne {
    background-color: #B0B3B4;
    border-color: #B0B3B4;
  }
  
  .counter-btn_plus {
    background-color: #f5c41d;
    border-color: #f5c41d;
    margin-left: 4px;
  }
  
  .price {
    color: #7b170f;
    margin-top: 8px;
  }

  .start {
    margin-left: 19.2px;
  }
  
  .end {
    align-items: flex-end;
    margin-right: 12.8px;
    width: 30%;
  }
  
  .line {
    width: 70%;
    background-color: #e00b19;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
  }