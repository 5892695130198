.pageTitle {
    width: 100%;
    text-align: center;
    margin-top: 50px;
    font-size: 25.6px;
    padding-left: 20px;
    padding-right: 20px;
}

.orderId {
    font-weight: 600;
}

.sectionContainer {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
    width: 320px;
}

.backTitleContainer {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    width: 320px;
    align-items: center;
    justify-content: space-between;
    margin-top: 60.8px;
}

.backTitle {
    font-size: 19.2px;
    color: #7b170f;
    width: 50%;
}

.empty {
    width: 0px;
}

.sectionTitle {
    color: #7b170f;
    font-size: 25.6px;
    margin-bottom: 19.2px;
    margin-top: 36.8px;
}

.cordonneesData {
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #b1b1b152;
    border-radius: 8px;
    opacity: 1;
    padding: 10px;
    font-size: 19.2px;
}

.coordonneesColumn {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.coordonneesRow {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 19.2px;
}

.firstRow {
    margin-top: 25.6px;
}

.lastRow {
    margin-bottom: 25.6px;
}

.coordonneesTitle {
    color: #b0b3b4;
}

.blue {
    color: #0369b2;
}

/*************** Commande détails ****************/

.sectionContainer table {
    width: 1296px;
    box-shadow: 0px 4px 16px #b1b1b152;
}

.sectionContainer table th {
    background-color: #7b170f;
    color: #ffffff;
    height: 61.6px;
}

.sectionContainer table th,
.sectionContainer table td {
    text-align: left;
    padding-left: 56px;
}

.sectionContainer table td {
    font-weight: 600;
}

.tableImg {
    width: 115.2px;
    height: 76.8px;
    object-fit: cover;
    border-radius: 0px 32px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.tableHighlightBlue {
    color: #0369b2;
}

.firstTh {
    border-radius: 8px 0px 0px 0px;
}

.lastTh {
    border-radius: 0px 8px 0px 0px;
}

.tableRow {
    width: 1296px;
    height: 61.6px;
    display: flex;
    align-items: center;
    border-radius: 0px 0px 8px 8px;
    background-color: #7b170f;
    color: #ffffff;
    padding-left: 56px;
    font-weight: 600;
}

.tableFoot {
    height: 61.6px;
    display: flex;
    align-items: center;
    border-radius: 0px 0px 8px 8px;
    background-color: #7b170f;
    color: #ffffff;
    padding-left: 56px;
    font-weight: 600;
}


.displayRow {
    display: flex;
    align-items: center;
}

.bonusPoints {
    margin-right: 56px;
}

.totalPrice {
    flex-grow: 1;
    text-align: right;
    margin-right: 56px;
    color: #f5c41d;
}


/**************** Media Query *******************/

@media (min-width: 576px) {
    .sectionContainer {
        min-width: 500px;
    }

    .backTitleContainer {
        width: 450px;
    }

    .coordonneesColumn {
        margin-left: 20px;
    }
}

@media (min-width: 768px) {
    .sectionContainer {
        min-width: 600px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .backTitleContainer {
        min-width: 600px;
    }

    .backTitle {
        font-size: 25.6px;
        width: fit-content;
    }

    .empty {
        width: 82.713px;
    }

    .cordonneesData {
        padding: 0px;
    }

    .coordonneesRow {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .coordonneesTitle {
        width: 150px;
        text-align: right;
        margin-right: 20px;
    }

    .data {
        width: 71.6%;
    }
}

@media (min-width: 992px) {
    .sectionContainer {
        min-width: 960px;
    }

    .backTitleContainer {
        min-width: 960px;
    }
}

@media (min-width: 1200px) {
    .sectionContainer {
        min-width: 1140px;
    }

    .backTitleContainer {
        min-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .sectionContainer {
        min-width: 1320px;
    }

    .backTitleContainer {
        min-width: 1320px;
    }
}